<template>
  <div class="home text-center white--text">
    <br><br>
    СКУД "ПроходЪ"
    <br><br>

    Следите, чтоб красных значков
    <br>
    сверху не было!

    <br><br>
    <v-icon class="red--text">mdi-account-off</v-icon>

    <!--      загрузили спектакль и его реализованные билеты с qr-->
    <v-icon class="red--text">mdi-ticket</v-icon>

<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
